var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLoaded)?_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary darken-2"}})],1):_c('div',[_c('NewTaskDialog',{attrs:{"project-id":_vm.projectId,"template-id":_vm.itemId,"template-alias":_vm.item.name,"template-type":_vm.item.type,"template-app":_vm.item.app},model:{value:(_vm.newTaskDialog),callback:function ($$v) {_vm.newTaskDialog=$$v},expression:"newTaskDialog"}}),_c('EditTemplateDialogue',{attrs:{"project-id":_vm.projectId,"item-app":_vm.item.app,"item-id":_vm.itemId},on:{"save":function($event){return _vm.loadData()}},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}),_c('EditTemplateDialogue',{attrs:{"project-id":_vm.projectId,"item-app":_vm.item.app,"item-id":"new","source-item-id":_vm.itemId},on:{"save":_vm.onTemplateCopied},model:{value:(_vm.copyDialog),callback:function ($$v) {_vm.copyDialog=$$v},expression:"copyDialog"}}),_c('ObjectRefsDialog',{attrs:{"object-title":"template","object-refs":_vm.itemRefs,"project-id":_vm.projectId},model:{value:(_vm.itemRefsDialog),callback:function ($$v) {_vm.itemRefsDialog=$$v},expression:"itemRefsDialog"}}),_c('YesNoDialog',{attrs:{"title":_vm.$t('deleteTemplate'),"text":_vm.$t('askDeleteTemp')},on:{"yes":function($event){return _vm.remove()}},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}}),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.showDrawer()}}}),_c('v-toolbar-title',{staticClass:"breadcrumbs"},[_c('router-link',{staticClass:"breadcrumbs__item breadcrumbs__item--link",attrs:{"to":_vm.viewId
            ? `/project/${_vm.projectId}/views/${_vm.viewId}/templates/`
            : `/project/${_vm.projectId}/templates/`}},[_vm._v(" "+_vm._s(_vm.$t('taskTemplates2'))+" ")]),_c('v-icon',[_vm._v("mdi-chevron-right")]),_c('span',{staticClass:"breadcrumbs__item"},[_vm._v(_vm._s(_vm.item.name))])],1),_c('v-spacer'),_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","depressed":""},on:{"click":function($event){_vm.newTaskDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.TEMPLATE_TYPE_ACTION_TITLES[_vm.item.type]))+" ")]),(_vm.canUpdate)?_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.askDelete()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),(_vm.canUpdate)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.copyDialog = true}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e(),(_vm.canUpdate)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.editDialog = true}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1),_c('v-tabs',{staticClass:"mb-4 ml-4"},[_c('v-tab',{attrs:{"to":`/project/${_vm.item.project_id}${
        _vm.$route.params.viewId ? `/views/${_vm.$route.params.viewId}` : ''
      }/templates/${_vm.item.id}/tasks`}},[_vm._v("Tasks")]),_c('v-tab',{attrs:{"to":`/project/${_vm.item.project_id}${
        _vm.$route.params.viewId ? `/views/${_vm.$route.params.viewId}` : ''
      }/templates/${_vm.item.id}/details`}},[_vm._v("Details")]),(['terraform', 'tofu'].includes(_vm.item.app))?_c('v-tab',{attrs:{"to":`/project/${_vm.item.project_id}${
        _vm.$route.params.viewId ? `/views/${_vm.$route.params.viewId}` : ''
      }/templates/${_vm.item.id}/state`}},[_vm._v(" State "),_c('v-icon',{staticClass:"ml-1",attrs:{"large":"","color":"hsl(348deg, 86%, 61%)"}},[_vm._v("mdi-professional-hexagon")])],1):_vm._e()],1),_c('router-view',{attrs:{"template":_vm.item,"inventory":_vm.inventory,"environment":_vm.environment,"repositories":_vm.repositories}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }