var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLoaded())?_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-alert',{staticClass:"pb-2",attrs:{"value":_vm.formError,"color":"error"}},[_vm._v(_vm._s(_vm.formError)+" ")]),_c('v-alert',{attrs:{"color":"blue","dark":"","icon":"mdi-source-fork","dismissible":"","prominent":""},model:{value:(_vm.commitAvailable),callback:function ($$v) {_vm.commitAvailable=$$v},expression:"commitAvailable"}},[_c('div',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s((_vm.item.commit_hash || '').substr(0, 10))+" ")]),(_vm.sourceTask && _vm.sourceTask.commit_message)?_c('div',[_vm._v(_vm._s(_vm.sourceTask.commit_message))]):_vm._e()]),(_vm.template.type === 'deploy')?_c('v-select',{attrs:{"label":_vm.$t('buildVersion'),"items":_vm.buildTasks,"item-value":"id","item-text":(itm) => _vm.getTaskMessage(itm),"rules":[v => !!v || _vm.$t('build_version_required')],"required":"","disabled":_vm.formSaving},model:{value:(_vm.item.build_task_id),callback:function ($$v) {_vm.$set(_vm.item, "build_task_id", $$v)},expression:"item.build_task_id"}}):_vm._e(),_c('v-text-field',{attrs:{"label":_vm.$t('messageOptional'),"disabled":_vm.formSaving},model:{value:(_vm.item.message),callback:function ($$v) {_vm.$set(_vm.item, "message", $$v)},expression:"item.message"}}),_vm._l((_vm.template.survey_vars || []),function(v){return _c('div',{key:v.name},[(v.type === 'secret')?_c('v-text-field',{attrs:{"label":v.title,"hint":v.description,"required":v.required,"type":"password","rules":[
          val => !v.required || !!val || v.title + _vm.$t('isRequired'),
        ]},model:{value:(_vm.editedSecretEnvironment[v.name]),callback:function ($$v) {_vm.$set(_vm.editedSecretEnvironment, v.name, $$v)},expression:"editedSecretEnvironment[v.name]"}}):(v.type === 'enum')?_c('v-select',{attrs:{"clearable":"","label":v.title + (v.required ? ' *' : ''),"hint":v.description,"required":v.required,"rules":[
        val => !v.required || val != null || v.title + ' ' + _vm.$t('isRequired')
      ],"items":v.values,"item-text":"name","item-value":"value"},model:{value:(_vm.editedEnvironment[v.name]),callback:function ($$v) {_vm.$set(_vm.editedEnvironment, v.name, $$v)},expression:"editedEnvironment[v.name]"}}):_c('v-text-field',{attrs:{"label":v.title + (v.required ? ' *' : ''),"hint":v.description,"required":v.required,"rules":[
        val => !v.required || !!val || v.title + ' ' + _vm.$t('isRequired'),
        val => !val || v.type !== 'int' || /^\d+$/.test(val) ||
        v.title + ' ' + _vm.$t('mustBeInteger'),
      ]},model:{value:(_vm.editedEnvironment[v.name]),callback:function ($$v) {_vm.$set(_vm.editedEnvironment, v.name, $$v)},expression:"editedEnvironment[v.name]"}})],1)}),(_vm.template.app === 'ansible')?_c('TaskParamsForm',{attrs:{"app":_vm.template.app},model:{value:(_vm.item.params),callback:function ($$v) {_vm.$set(_vm.item, "params", $$v)},expression:"item.params"}}):_c('TaskParamsForm',{attrs:{"app":_vm.template.app},model:{value:(_vm.item.params),callback:function ($$v) {_vm.$set(_vm.item, "params", $$v)},expression:"item.params"}}),(_vm.template.allow_override_args_in_task)?_c('ArgsPicker',{attrs:{"vars":_vm.args,"title":"CLI args"},on:{"change":_vm.setArgs}}):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }